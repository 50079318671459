<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body">
                    <ValidationObserver ref="observer" slim>
                        <div class="row">
                            <div class="col-12">
                                <p class="f-16 font-600 text-black">User Account</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <ValidationProvider name="email" v-slot="{ errors }" rules="required|email" slim>
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Email <span class="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            v-model="user.email"
                                            class="form-control"
                                            placeholder="Masukkan Email"
                                            :disabled="pageType === 'edit'"
                                        >
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider name="first name" v-slot="{ errors }" rules="required" slim>
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="" class="f-14 text-secondary required">Nama Depan<span class="text-danger">*</span></label>
                                                <input type="text" v-model="user.firstName" class="form-control" placeholder="Masukkan Nama Depan ">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider name="last name" v-slot="{ errors }" slim>
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="" class="f-14 text-secondary required">Nama Belakang</label>
                                                <input type="text" v-model="user.lastName" class="form-control" placeholder="Masukkan Nama Belakang">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <ValidationProvider
                                    name="phone number"
                                    v-slot="{ errors }"             
                                    :rules="{ required: true, regex: /^08[1-9][0-9]{7,11}$/ }"
                                    slim
                                >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Nomor Handphone <span class="text-danger">*</span></label>
                                        <input type="text" v-model="user.mobileNumber" class="form-control" placeholder="Masukkan Nomor Handphone">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="birthday" v-slot="{ errors }" rules="required" slim>
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Tanggal Lahir <span class="text-danger">*</span></label>
                                        <date-picker
                                            v-model="user.dateOfBirth"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal"
                                            style="width: 100%"
                                            :disabled-date="disabledDate"
                                            :default-value="$moment().subtract(17, 'years')"
                                        />
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ul class="form-group">
                                    <li class="col-6 p-0">
                                        <button 
                                            type="button" 
                                            class="gender left" 
                                            :class="(user && user.gender === 'M') ? 'active' : ''"
                                            @click="setGender('M')"
                                        >
                                            Laki-Laki
                                        </button>
                                    </li>
                                    <li class="col-6 p-0">
                                        <button 
                                            type="button" 
                                            class="gender right" 
                                            :class="(user && user.gender === 'F') ? 'active' : ''"
                                            @click="setGender('F')"
                                        >
                                            Perempuan
                                        </button>
                                    </li>
                                </ul>
                                <div class="form-group row" v-if="user && user.gender === 'F'">
                                    <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                        <input type="checkbox" v-model="user.pregnant">
                                        Hamil
                                    </label>
                                    <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                        <input type="checkbox" v-model="user.breastFeeding">
                                        Menyusui
                                    </label>
                                    <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                        <input type="checkbox" v-model="user.promil">
                                        Program Hamil
                                    </label>
                                    <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                        <input type="checkbox" v-model="user.kb">
                                        Menggunakan KB
                                    </label>
                                    <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                        <input type="checkbox" v-model="user.preferFemaleDoctor">
                                        Menggunakan Hijab
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <ValidationProvider
                                    name="identity card"
                                    v-slot="{ errors }"                    
                                    rules="numeric_only|max:20"
                                    slim
                                >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Identity Card</label>
                                        <input
                                            type="text"
                                            v-model="user.idCard"
                                            class="form-control"
                                            placeholder="Masukkan Identity Card"
                                            @input="() => { user.idCard = user.idCard.replace(/[^0-9]/g, '') }"
                                        >
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group">
                                    <label for="" class="f-14 text-secondary">Omnicare EMR</label>
                                    <input type="text" v-model="user.omnicarePatientId" class="form-control" placeholder="Masukkan Omnicare EMR">
                                </div>
                                <div class="form-group" :class="{ 'validation-warning': validated && generateAddressString === '' }">
                                    <label for="" class="f-14 text-secondary">Address  <span class="text-danger">*</span></label>
                                    <div id="address-string-content" class="position-relative cursor-pointer" @click="openCreateNewModal()">
                                        <span class="f-12">{{ generateAddressString }}</span>
                                        <i class="fa fa-chevron-right address-arrow-right"></i>
                                    </div>
                                    <small
                                        class="text-muted"
                                        v-if="validated && generateAddressString === ''"
                                    >
                                        This address field is required
                                    </small>
                                </div>
                                <ValidationProvider
                                    name="address detail"
                                    v-slot="{ errors }"                    
                                    rules="required|address_symbols|max:200"
                                    slim
                                >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <textarea
                                            cols="30"
                                            rows="3"
                                            class="form-control"
                                            placeholder="Masukkan Address Detail (Contoh: Nama Gedung / Jalan / Nomor / Blok)"
                                            v-model="user.address.address"
                                            style="resize: none; min-height: 90px; max-height: 90px;"
                                        >
                                        </textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="address note"
                                    v-slot="{ errors }"     
                                    rules="address_symbols|max:45"
                                    slim
                                >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <input type="text" v-model="user.address.notes" class="form-control" placeholder="Masukkan Address Note (Contoh: Pagar Hitam)">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <router-link :to="getSourceUrl()" class="btn text-danger">Batal</router-link>
                            <button type="submit" class="btn btn-primary" :disabled="isLoading" @click="onSubmit()">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <!-- Create new address modal -->
        <modal :name="CREATE_NEW_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000">
            <div class="modal-container p-2 position-relative">
                <div class="btn-close-modal" @click="closeCreateNewModal()">
                    <i class="fa fa-times cursor-pointer"></i>
                </div>
                <p class="f-16 text-black font-600" style="padding-right: 32px;">Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos</p>
                <ValidationObserver ref="modalAddressObserver">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <ValidationProvider name="province" v-slot="{ errors }" rules="required" slim>
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Provinsi</label>
                                    <multiselect
                                        placeholder="Pilih Provinsi"
                                        v-model="modal.address.province"
                                        :options="modal.options.province || []"
                                        label="name"
                                        track-by="id"
                                        select-label=""
                                        deselect-label=""
                                        :multiple="false"
                                        :allow-empty="false"
                                        @input="(newValue) => onSelectAddress({ ...newValue }, 'province')"
                                        :loading="!modal.options.province"
                                        :max-height="120"
                                    >
                                        <span slot="noResult">Oops! No data found.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6">
                            <ValidationProvider name="city" v-slot="{ errors }" rules="required" slim>
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Kota</label>
                                    <multiselect
                                        placeholder="Pilih Kota"
                                        v-model="modal.address.city"
                                        :options="modal.options.city || []"
                                        label="name"
                                        track-by="id"
                                        select-label=""
                                        deselect-label=""
                                        :multiple="false"
                                        :allow-empty="false"
                                        @input="(newValue) => onSelectAddress({ ...newValue }, 'city')"
                                        :loading="modal.address.province && !modal.options.city"
                                        :disabled="!modal.address.province || !modal.options.city"
                                        :max-height="120"
                                    >
                                        <span slot="noResult">Oops! No data found.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6">
                            <ValidationProvider name="suburb" v-slot="{ errors }" rules="required" slim>
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Kecamatan</label>
                                    <multiselect
                                        placeholder="Pilih Kecamatan"
                                        v-model="modal.address.suburb"
                                        :options="modal.options.suburb || []"
                                        label="name"
                                        track-by="id"
                                        select-label=""
                                        deselect-label=""
                                        :multiple="false"
                                        :allow-empty="false"
                                        @input="(newValue) => onSelectAddress({ ...newValue }, 'suburb')"
                                        :loading="modal.address.city && !modal.options.suburb"
                                        :disabled="!modal.address.city || !modal.options.suburb"
                                        :max-height="120"
                                    >
                                        <span slot="noResult">Oops! No data found.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6">
                            <ValidationProvider name="area" v-slot="{ errors }" rules="required" slim>
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Kelurahan, Kode Pos</label>
                                    <multiselect
                                        placeholder="Pilih Kelurahan"
                                        v-model="modal.address.area"
                                        :options="modal.options.area || []"
                                        label="name"
                                        track-by="id"
                                        select-label=""
                                        deselect-label=""
                                        :custom-label="({ name, postcode }) => `${name}, ${postcode}`"
                                        :multiple="false"
                                        :allow-empty="false"
                                        @input="(newValue) => onSelectAddress({ ...newValue }, 'area')"
                                        :loading="modal.address.suburb && !modal.options.area"
                                        :disabled="!modal.address.suburb || !modal.options.area"
                                        :max-height="120"
                                    >
                                        <span slot="noResult">Oops! No data found.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <button
                        type="submit"
                        style="margin-top: 42px;"
                        class="btn btn-primary rounded w-100"
                        @click="saveAddress()"
                    >
                        Simpan Alamat
                    </button>
                </ValidationObserver>
            </div>
        </modal>
    </div>
</template>

<script>
    const CREATE_NEW_MODAL = 'create_new_modal';

    export default {
        props: {
            userProps: {
                type: Object,
                default: undefined,
            },
            pageType: String, // add, edit
        },
        data() {
            return {
                CREATE_NEW_MODAL,
                user: {
                    email: this.userProps?.email ?? "",
                    firstName: this.userProps?.firstName ?? "",
                    lastName: this.userProps?.lastName ?? "",
                    mobileNumber: this.userProps?.mobileNumber ?? "",
                    dateOfBirth: this.userProps?.dateOfBirth ?? "",
                    gender: this.userProps?.gender ?? "M",
                    pregnant: this.userProps?.pregnant ?? false,
                    breastFeeding: this.userProps?.breastFeeding ?? false,
                    promil: this.userProps?.promil ?? false,
                    kb: this.userProps?.kb ?? false,
                    preferFemaleDoctor: this.userProps?.preferFemaleDoctor ?? false,
                    idCard: this.userProps?.idCard ?? '',
                    omnicarePatientId: this.userProps?.omnicarePatientId ?? '',
                    address: {
                        province: this.userProps?.address?.provinceId
                            ? { id: this.userProps.address.provinceId, name: this.userProps.address.province } : null,
                        city: this.userProps?.address?.cityId
                            ? { id: this.userProps.address.cityId, name: this.userProps.address.city } : null,
                        suburb: this.userProps?.address?.suburbId
                            ? { id: this.userProps.address.suburbId, name: this.userProps.address.suburb } : null,
                        area: this.userProps?.address?.areaId
                            ? { id: this.userProps.address.areaId, name: this.userProps.address.area, postcode: this.userProps.address.postalCode } : null,
                        address: this.userProps?.address?.address ?? null,
                        notes: this.userProps?.address?.notes ?? null,
                    },
                },
                modal: {
                    address: {},
                    options: {
                        province: [],
                    }
                },
                isLoading: false,
                validated: false,
            }
        },
        mounted() {
            this.getProvinces();
        },
        computed: {
            generateAddressString() {
                if (
                    this.user.address.province
                    && this.user.address.city
                    && this.user.address.suburb
                    && this.user.address.area
                ) {
                    const { province, city, suburb, area } = this.user.address;
                    return `${province.name}, ${city.name}, ${suburb.name}, ${area.name} ${area.postcode}`;
                }
                return '';
            }
        },
        methods: {
            setGender(gender){
                this.user.gender = gender;
            },
            disabledDate(date) {
                return (
                    this.$moment()
                    && date.setHours(0, 0, 0, 0) > new Date(this.$moment().subtract(17, 'years')).setHours(0, 0, 0, 0)
                );
            },
            openCreateNewModal() {
                this.modal.address = { ...this.user.address };
                console.log(this.modal.address)
                if (this.generateAddressString !== '') {
                    Promise.all([
                        this.getCities(this.user.address.province.id),
                        this.getSuburbs(this.user.address.city.id),
                        this.getPostalCodes(this.user.address.suburb.id),
                    ]);
                }
                this.$modal.show(CREATE_NEW_MODAL);
            },
            closeCreateNewModal() {
                // reset options state on modal close (except province)
                this.modal.options = {
                    province: this.modal.options.province,
                }
                this.$modal.hide(CREATE_NEW_MODAL);
            },
            getProvinces() {
                this.axios
                    .get('/misc/provinces')
                    .then((res) => {
                        this.modal.options.province = res.data.data;
                    })
            },
            getCities(provinceId) {
                this.axios
                    .get(`/misc/cities?provinceId=${provinceId}`)
                    .then((res) => {
                        this.modal.options = {
                            ...this.modal.options,
                            city: res.data.data,
                        };
                    })
            },
            getSuburbs(cityId) {
                this.axios
                    .get(`/misc/suburbs?cityId=${cityId}`)
                    .then((res) => {
                        this.modal.options = {
                            ...this.modal.options,
                            suburb: res.data.data,
                        };
                    })
            },
            getPostalCodes(suburbId) {
                this.axios
                    .get(`/misc/postalcodes?suburbId=${suburbId}`)
                    .then((res) => {
                        this.modal.options = {
                            ...this.modal.options,
                            area: res.data.data,
                        };
                    })
            },
            getSourceUrl() {
                const userId = this.$route.params.id;
                let sourceUrl = '/offline-clinic-booking/user';
                if (this.$route.query.source && userId) {
                    switch(this.$route.query.source) {
                        case 'create-appointment':
                            sourceUrl = `/offline-clinic-booking/user/${userId}/appointment`;
                            break;
                        case 'user-detail':
                            sourceUrl = `/offline-clinic-booking/user/${userId}`;
                            break;
                        case 'booking-detail':
                            if (this.$route.query.bookingId) {
                                sourceUrl = `/offline-clinic-booking/${this.$route.query.bookingId}`;
                            }
                            break;
                    }
                }
                return sourceUrl;
            },
            async onSelectAddress(newValue, dependKey) {
                const fetchList = {
                    city: this.getCities,
                    suburb: this.getSuburbs,
                    area: this.getPostalCodes,
                }

                let dependedFields = [];
                switch (dependKey) {
                    case "province":
                        dependedFields = ["city", "suburb", "area"];
                        break;
                    case "city":
                        dependedFields = ["suburb", "area"];
                        break;
                    case "suburb":
                        dependedFields = ["area"];
                        break;
                    default:
                        dependedFields = [];
                        break;
                }

                for (let field of dependedFields) {
                    // reset depended fields value and options
                    this.modal.address[field] = null;
                    delete this.modal.options[field];
                }

                // refetch the very first depended fields
                // to get options based on new selected depend key
                if (dependedFields[0]) {
                    const { id: newValueId, name: newValueName } = newValue ? newValue : { id: "", name: "" };
                    if (newValueId && newValueName) fetchList[dependedFields[0]](newValueId);
                }
            },
            async saveAddress() {
                const isValid = await this.$refs.modalAddressObserver.validate();
                if (isValid) {
                    this.user.address = {
                        ...this.user.address,
                        ...this.modal.address,
                    }

                    this.$modal.hide(CREATE_NEW_MODAL);
                    if (this.$refs.modalAddressObserver) {
                        this.$refs.modalAddressObserver.reset();
                    }
                }
            },
            async onSubmit() {
                const isValid = await this.$refs.observer.validate();
                this.validated = true;
                if (isValid) {
                    if (this.generateAddressString === '') return;
                    const payload = {
                        ...this.user,
                        dateOfBirth: this.$moment(this.user.dateOfBirth).format('YYYY-MM-DD'),
                        address: {
                            province: this.user.address.province.name,
                            provinceId: this.user.address.province.id,
                            city: this.user.address.city.name,
                            cityId: this.user.address.city.id,
                            suburb: this.user.address.suburb.name,
                            suburbId: this.user.address.suburb.id,
                            area: this.user.address.area.name,
                            areaId: this.user.address.area.id,
                            postalCode: this.user.address.area.postcode,
                            address: this.user.address.address,
                            notes: this.user.address.notes,
                        },
                    };
                    this.isLoading = true;
                    if (this.pageType === 'add') {
                        this.axios
                            .post('/v2/clinical/offline-clinic-booking/users', { ...payload })
                            .then((res) => {
                                this.$swal.fire({
                                    title: res.data.message,
                                    text: 'User created',
                                    icon: 'success',
                                    confirmButtonColor: '#0036A0',
                                    confirmButtonText: 'OK'
                                })
                                this.$router.push(`/offline-clinic-booking/user/${res.data.data.id}`);
                            })
                            .catch((err) => {
                                this.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.message,
                                });
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    } else {
                        this.axios
                            .put(`/v2/clinical/offline-clinic-booking/users/${this.$route.params.id}`, { ...payload })
                            .then((res) => {
                                this.$swal.fire({
                                    title: res.data.message,
                                    text: 'User updated',
                                    icon: 'success',
                                    confirmButtonColor: '#0036A0',
                                    confirmButtonText: 'OK'
                                })
                                this.$router.push(this.getSourceUrl());
                            })
                            .catch((err) => {
                                this.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.message,
                                });
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .gap-1 {
        gap: 0.5rem;
    }
    ul.form-group{
        list-style: none;
        display: flex;
        padding: 0;
    }
    .gender {
        cursor: pointer;
        padding: 12px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #FFFFFF;
        color: #626262;
        border: 1px solid #626262;
    }
    .gender.left{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .gender.right{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .gender.active {
        background: #0036A0;
        color: #FFFFFF;
        border: 1px solid #0036A0;
    }
    #address-string-content {
        border: 1px solid #C4C4C4;
        font-size: 14px;
        border-radius: 6px;
        padding: 8px 32px 8px 14px;
        background-color: #FFF;
        min-height: 38px;
    }
    .address-arrow-right {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    .modal-container{
        overflow: auto;
        padding: 1rem;
        height: 100%;
    }
    .btn-close-modal {
        width: 20px;
        height: 20px;
        border-radius: 666px;
        background: #C4C4C4;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 22px;
        top: 22px;
        cursor: pointer;
    }
</style>