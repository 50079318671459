<template>
    <div class="section mw-100 p-2">
        <div class="card">
            <div class="card-body p-0">
                <div class="row pl-2 pt-2 pr-2 pb-1" style="gap: 1rem">
                    <p class="align-self-center f-16 font-600 text-black ml-2 mb-0">Status</p>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': status === null, 'btn-outline-secondary': status !== null}" @click="handleChangeStatus(null)">Semua</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': status === 0, 'btn-outline-secondary': status !== 0}"  @click="handleChangeStatus(0)">Pesanan Baru</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': status === 1, 'btn-outline-secondary': status !== 1}"  @click="handleChangeStatus(1)">On Proses</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': status === 2, 'btn-outline-secondary': status !== 2}"  @click="handleChangeStatus(2)">Selesai</button>
                    <button type="button" class="btn btn-sm" :class="{'btn-primary': status === 3, 'btn-outline-secondary': status !== 3}"  @click="handleChangeStatus(3)">Dibatalkan</button>
                    
                    <p class="align-self-center f-16 font-600 text-blue ml-1 mb-0 pointer"
                        @click="resetFilter">
                        Reset Filter
                    </p>
                </div>

                <div class="row pl-2 pr-2 pb-1" style="gap: 1rem">
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <p class="flex-shrink-0 mb-0 f-14 font-600">Parameter pencarian</p>
                        <multiselect
                            v-model="searchParam"
                            :options="[
                                { value: 'consultId', label: 'Konsul ID' },
                                { value: 'orderNumber', label: 'Transaksi ID' },
                                { value: 'userName', label: 'User name' },
                                { value: 'receiverName', label: 'Receiver name' },
                                { value: 'userPhone', label: 'User phone number' },
                                { value: 'receiverPhone', label: 'Receiver phone number' },
                                { value: 'email', label: 'Email' },
                            ]"
                            label="label"
                            track-by="value"
                            :show-labels="false"
                            :multiple="false"
                            :searchable="false"
                            :allow-empty="false"
                            class="ml-1"
                            @input="handleChangeSearchParam()"
                        >
                        </multiselect>
                    </div>
                    <div class="input-group col-12 col-md-3">
                        <span class="input-group-prepend">
                            <div class="input-group-text border-right-0 bg-transparent text-blue">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                        <input
                            class="form-control border-left-0"
                            v-model="search"
                            @input="handleSearch"
                            type="search"
                            placeholder="Cari transaksi..."
                        >
                    </div>
                </div>
                <div class="row pl-2 pr-2 pb-1" style="gap: 1rem">
                    <div class="col-12 col-md-4 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Tanggal</p>
                        <date-picker
                            v-model="date"
                            format="DD/MM/YYYY"
                            type="date"
                            input-class="form-control"
                            value-type="YYYY-MM-DD"
                            placeholder="Pilih tanggal"
                            :range="true"
                            :disabled-date="disabledDate"
                            style="width: 100%"
                            @input="hasTablePermission ? getList() : undefined"
                        />
                    </div>
                    <div class="col-lg-3 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Tipe</p>
                        <multiselect 
                            v-model="type"
                            placeholder="Tipe Transaksi"
                            :options="[
                                { value: 1, label: getOrderTypeLabel(1) },
                                { value: 2, label: getOrderTypeLabel(2) },
                                { value: 3, label: getOrderTypeLabel(3) },
                                { value: 4, label: getOrderTypeLabel(4) },
                                { value: 5, label: getOrderTypeLabel(5) },
                                { value: 6, label: getOrderTypeLabel(6) },
                            ]"
                            label="label"
                            track-by="value"
                            select-label=""
                            deselect-label="Reset"
                            :multiple="false"
                            :searchable="false"
                            @input="hasTablePermission ? getList() : undefined"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 d-flex align-items-center" style="gap: 1rem">
                        <p class="align-self-center mb-0 f-14 font-600">Poliklinik</p>
                        <multiselect 
                            v-model="polyclinic"
                            placeholder="Poliklinik"
                            :options="polyclinicOptions"
                            select-label=""
                            deselect-label="Reset"
                            :multiple="false"
                            :searchable="false"
                            :loading="polyclinicOptions.length === 0"
                            @input="hasTablePermission ? getList() : undefined"
                        >
                        </multiselect>
                    </div>
                </div>
                <div v-if="hasTablePermission" class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                            <th scope="col" class="font-600 text-black">Transaksi ID</th>
                            <th scope="col" class="font-600 text-black">Konsul ID</th>
                            <th scope="col" class="font-600 text-black">Tgl Transaksi</th>
                            <th scope="col" class="font-600 text-black">Status Transaksi</th>
                            <th scope="col" class="font-600 text-black">Tipe</th>
                            <th scope="col" class="font-600 text-black">Nama</th>
                            <th scope="col" class="font-600 text-black">Poliklinik</th>
                            <th scope="col" class="font-600 text-black">Pesanan</th>
                            <th scope="col" class="font-600 text-black">Metode Pembayaran</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, key) in table.dataTable.lists.rows" :key="key">
                                <td>
                                    <router-link :to="`/daftar-transaksi/${order.id}`" style="text-decoration: underline">
                                        {{ order.orderNumber }}
                                    </router-link>
                                </td>
                                <td>{{ order.consult && order.consult.consultId ? order.consult.consultId : '-' }}</td>
                                <td style="white-space: pre;">{{ order.createdAt | moment('LL[\n]HH:mm')  }}</td>
                                <td><div class="btn btn-sm" :class="{ 'btn-success': order.statusTransaction == 0, 'btn-warning': order.statusTransaction == 1, 'btn-primary': order.statusTransaction == 2, 'btn-danger': order.statusTransaction == 3 }" >
                                    {{ order.statusTransactionDetail ?  order.statusTransactionDetail.name : '-'}}
                                </div></td>
                                <td style="white-space: pre;">{{ getOrderTypeLabel(order.type) }}</td>
                                <td>{{ order.user.isVip ? `👑 ${order.user.fullName}` : order.user.fullName }}</td>
                                <td>{{ order.subClinic ? order.subClinic : '-' }}</td>
                                <td>
                                    <span v-if="order.type === 6">{{ order.packageName }}</span>
                                    <ul v-else class="pl-1">
                                        <li v-for="(pr, k)  in order.productReference" :key="k">{{ pr.name ? pr.name : pr }}</li>
                                    </ul>
                                </td>
                                <td>{{ order.paymentChannel ? order.paymentChannel.name : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="hasTablePermission" class="col text-center mt-1">
                    <p class="f-14" v-if="table.dataTable.lists.count == 0 || !table.dataTable.lists.rows">There is no data</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body pl-3 d-flex flex-row justify-content-between flex-wrap" style="gap: 1rem">
                <PaginateList :data="table.dataTable.lists" v-if="hasTablePermission && table.dataTable.lists.total != 0"/>
                <button v-if="hasDownloadPermission" type="button" class="btn btn-sm btn-primary" @click="download" :disabled="isLoadingDownload">
                    <span v-if="isLoadingDownload">
                        Downloading <i class="fa fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>Download</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
import { debounce } from "debounce";
import { getOrderTypeLabel } from '@/lib/order';

export default {
    components: {
        // Table,
        PaginateList
    },
    data() {
        return {
            orders: [],
            table: {
                dataTable: {
                    header: ['User', 'Email', 'Phone', 'Terakhir Order', 'Status', 'Action'],
                    columns: ['user', 'email', 'phone', 'last_order', 'status'],
                    lists: [],
                }
            },
            searchParam: { value: 'orderNumber', label: 'Transaksi ID' },
            search: '',
            limit: 10,
            status: null,
            date: [null, null],
            type: null,
            polyclinic: '',
            polyclinicOptions: [],
            isLoadingDownload: false,
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
        sanitizedSearchMessage() {
            if (this.searchParam.value === 'email') {
                return this.search.replace(/ /g, '');
            }
            if (this.searchParam.value === 'userPhone' || this.searchParam.value === 'receiverPhone') {
                const trimmedMessage = this.search.replace(/ /g, '');
                if (trimmedMessage.startsWith('+62')) {
                    return `0${trimmedMessage.slice(3)}`;
                }
                return trimmedMessage;
            }
            return this.search;
        },
        permissions() {
            return this.$store.getters.permissions;
        },
        hasViewPermission() {
            return this.permissions.includes('transaction:view');
        },
        hasEditPermission() {
            return this.permissions.includes('transaction:edit');
        },
        hasDownloadPermission() {
            return this.permissions.includes('transaction:download');
        },
        hasTablePermission() {
            return this.hasViewPermission || this.hasEditPermission;
        },
    },
    mounted() {
        this.getPolyclinic();
        if (this.hasTablePermission) {
            this.getList();
            this.getList = debounce(this.getList, 500);
    
            this.$root.$on("paginate", (data) => {
                this.getList(data);
            });
    
            this.$root.$on("changeLimit", (data) => {
                this.limit = data;
            });
        }
    },
    methods: {
        getOrderTypeLabel,
        getList(page) {
            this.axios.get('/orders/transactions', {
                params: {
                    sortBy: 'createdAt.DESC',
                    page: page || 1,
                    limit: this.limit,
                    statusTransaction: this.status !== null ? `in:${this.status}` : undefined,
                    [this.searchParam.value]: this.sanitizedSearchMessage || undefined,
                    createdAt: this.date[0] !== null && this.date[1] !== null ? `betweenDate:${this.date[0]},${this.date[1]}` : undefined,
                    type: this.type && this.type.value ? this.type.value : undefined,
                    subClinic: this.polyclinic || undefined,
                }
            })
                .then(resp => {
                    this.table.dataTable.lists = resp.data.data;
                })
                .catch(err => {
                    console.log(err.response);
                });
        },
        handleChangeStatus(status) {
            this.status = status;
            if (this.hasTablePermission) {
                this.getList();
            }
        },
        handleChangeSearchParam() {
            if (this.search !== '' && this.hasTablePermission) {
                this.getList();
            }
        },
        handleSearch: debounce(function() {
            if (this.hasTablePermission) {
                this.getList();
            }
        }, 1000),
        getPolyclinic() {
            this.axios.get('/v2/misc/polyclinics', {
                params: {
                    page: 1,
                    includesDeleted: true,
                },
            })
                .then((res) => {
                    this.polyclinicOptions = res.data.data.rows.map(item => item.name);
                })
                .catch((err) => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message,
                    });
                });
        },
        download() {
            this.isLoadingDownload = true;
            this.axios.post('/orders/transactions/download', {
                sortBy: 'createdAt.desc',
                status: this.status ?? undefined,
                [this.searchParam.value]: this.search || undefined,
                startDate: this.date[0] || undefined,
                endDate: this.date[1] || undefined,
                type: this.type && this.type.value ? this.type.value : undefined,
                subClinic: this.polyclinic || undefined,
            })
                .then(res => {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        text: res.data.message,
                    });
                })
                .catch(err => {
                    this.$notify({
						type: 'error',
						title: 'Error',
						text: err.response.data.message,
					});
                })
                .finally(() => {
                    this.isLoadingDownload = false;
                })
        },
        disabledDate(date, currentValue) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const maxThirtyOneDays = currentValue[0] ? date > new Date(currentValue[0].getTime() + 30 * 24 * 3600 * 1000) : false;
            return date < new Date(today.getTime() - 365 * 24 * 3600 * 1000) || maxThirtyOneDays;
        },
        resetFilter() {
            this.status = null;
            this.searchParam = { value: 'orderNumber', label: 'Transaksi ID' };
            this.search = '';
            this.date = [null, null];
            this.type = null;
            this.polyclinic = '';
            if (this.hasTablePermission) {
                this.getList();
            }
        }
    },
}
</script>
