import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from 'js-cookie'
import store from '@/store';

import ConsulationPrescription from '../views/consultation_prescription/Index.vue'
import ConsultDetail from '../views/consult/Detail.vue'
import QuestionnaireAnswer from '../views/consultation_prescription/QuestionnaireAnswer.vue';

// import Undermaintenance from '../views/errors/503.vue'
// import NotFound from '../views/errors/404.vue'
// import Forbidden from '../views/errors/403.vue'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import EditProfile from '../views/user/EditProfile.vue'
import ChangePassword from '../views/user/ChangePassword.vue'

import TransactionIndex from '../views/transaction/Index'
import TransactionDetail from '../views/transaction/Detail'

import CustomerIndex from '../views/customer/Index.vue'
import CustomerDetail from '../views/customer/Detail.vue'
import CustomerForm from '../views/customer/Form.vue'

import ShippingIndex from '../views/shipping/Index.vue'
import ShippingDetail from '../views/shipping/Detail.vue'

import ClinicalUserSetting from '../views/clinical_user_setting/Index.vue';

import SatisfactionSurvey from '../views/satisfaction_survey/Index.vue';

import OfflineClinicScheduleIndex from '../views/offline_clinic_schedule/Index.vue';
import OfflineClinicScheduleDetail from '../views/offline_clinic_schedule/Detail.vue';

import OfflineClinicBookingIndex from '../views/offline_clinic_booking/Index.vue';
import OfflineClinicBookingUser from '../views/offline_clinic_booking/User.vue';
import OfflineClinicBookingUserDetail from '../views/offline_clinic_booking/UserDetail.vue';
import OfflineClinicBookingUserAdd from '../views/offline_clinic_booking/UserAdd.vue';
import OfflineClinicBookingUserEdit from '../views/offline_clinic_booking/UserEdit.vue';
import OfflineClinicBookingAppointmentForm from '../views/offline_clinic_booking/AppointmentForm.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                redirect: '/konsultasi-dan-resep'
            },
            {
                path: '/konsultasi-dan-resep',
                name: 'ConsulationPrescription',
                component: ConsulationPrescription,
                meta: {
                    title: 'Konsultasi & Resep'
                }
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile,
            },
            {
                path: '/user/change_password',
                name: 'ChangePassword',
                component: ChangePassword,
            },
            {
                path: '/daftar-pelanggan',
                name: 'CustomerIndex',
                component: CustomerIndex,
                meta: {
                    title: 'Daftar Pelanggan'
                }
            },
            {
                path: '/daftar-pelanggan/detail/:id',
                name: 'CustomerDetail',
                component: CustomerDetail,
                meta: {
                    title: 'Pelanggan / '
                }
            },
            {
                path: '/daftar-pelanggan/edit/:id',
                name: 'CustomerForm',
                component: CustomerForm,
                meta: {
                    title: 'Edit Profile / '
                }
            },
            {
                path: '/daftar-transaksi',
                name: 'TransactionIndex',
                component: TransactionIndex,
                meta: {
                    title: 'Daftar Transaksi'
                }
            },
            {
                path: '/daftar-transaksi/:id',
                name: 'TransactionDetail',
                component: TransactionDetail,
                meta: {
                    title: 'Detail Transaksi - ID: '
                }
            },
            {
                path: '/apotek-dan-pengiriman',
                name: 'ShippingIndex',
                component: ShippingIndex,
                meta: {
                    title: 'Apotek & Pengiriman'
                }
            },
            {
                path: '/apotek-dan-pengiriman/detail/:id',
                name: 'ShippingDetail',
                component: ShippingDetail,
                meta: {
                    title: 'Detail Transaksi - ID: '
                }
            },
            {
                path: '/konsultasi-dan-resep/:id',
                name: 'ConsultDetail',
                component: ConsultDetail,
                meta: {
                    title: 'Rekam Medis',
                    backButton: true,
                }
            },
            {
                path: '/clinical-user-setting',
                name: 'ClinicalUserSetting',
                component: ClinicalUserSetting,
                meta: {
                    title: 'Clinical Users',
                }
            },
            {
                path: '/satisfaction-survey',
                name: 'SatisfactionSurvey',
                component: SatisfactionSurvey,
                meta: {
                    title: 'Satisfaction Survey',
                }
            },
            {
                path: '/offline-clinic-schedule',
                name: 'OfflineClinicScheduleIndex',
                component: OfflineClinicScheduleIndex,
                meta: {
                    title: 'Offline Clinic Schedule',
                }
            },
            {
                path: '/offline-clinic-schedule/:id',
                name: 'OfflineClinicScheduleDetail',
                component: OfflineClinicScheduleDetail,
                meta: {
                    title: 'Offline Clinic Schedule - ',
                }
            },
            {
                path: '/offline-clinic-booking',
                name: 'OfflineClinicBookingIndex',
                component: OfflineClinicBookingIndex,
                meta: {
                    title: 'Offline Clinic Booking',
                }
            },
            {
                path: '/offline-clinic-booking/user',
                name: 'OfflineClinicBookingUser',
                component: OfflineClinicBookingUser,
                meta: {
                    title: 'New Appoinment',
                }
            },
            {
                path: '/offline-clinic-booking/user/add',
                name: 'OfflineClinicBookingUserAdd',
                component: OfflineClinicBookingUserAdd,
                meta: {
                    title: 'New Appoinment',
                }
            },
            {
                path: '/offline-clinic-booking/user/:id',
                name: 'OfflineClinicBookingUserDetail',
                component: OfflineClinicBookingUserDetail,
                meta: {
                    title: 'New Appoinment',
                }
            },
            {
                path: '/offline-clinic-booking/user/:id/edit',
                name: 'OfflineClinicBookingUserEdit',
                component: OfflineClinicBookingUserEdit,
                meta: {
                    title: 'New Appoinment',
                }
            },
            {
                path: '/offline-clinic-booking/user/:id/appointment',
                name: 'OfflineClinicBookingAppointmentForm',
                component: OfflineClinicBookingAppointmentForm,
                meta: {
                    title: 'New Appoinment',
                }
            }
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            },
            // {
            //     path: '*',
            //     name: 'NotFound',
            //     component: NotFound
            // }
        ]
    },
    {
        path: '/konsultasi-dan-resep/answer/:id',
        component: QuestionnaireAnswer,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if (!cookies.get('accessToken')) {
        next({name: 'Login'})
    } else {
        if (cookies.get('accessToken')){
			await store.dispatch('getMe');
		}
        next()
    }
})

export default router
