import ConsultationIcon from '@/assets/icons/menu/consultation-menu-icon.svg';
import ClinicalIcon from '@/assets/icons/menu/clinical-menu-icon.svg';
import SatisfactionSurveyIcon from '@/assets/icons/menu/satisfaction-survey-menu-icon.svg';
import ShippingIcon from '@/assets/icons/menu/shipping-menu-icon.svg';
import TransactionIcon from '@/assets/icons/menu/transaction-menu-icon.svg';
import UserIcon from '@/assets/icons/menu/user-menu-icon.svg';
import OfflineClinicBookingIcon from '@/assets/icons/menu/offline-clinic-booking-menu-icon.svg';
import OfflineClinicScheduleIcon from '@/assets/icons/menu/offline-clinic-schedule-menu-icon.svg';

export const MENUS = [
  {
    url: '/konsultasi-dan-resep',
    icon: ConsultationIcon,
    permissions: ['consult_prescription:view', 'consult_prescription:edit'],
  },
  {
    url: '/clinical-user-setting',
    icon: ClinicalIcon,
    permissions: ['clinical_user:view', 'clinical_user:edit'],
  },
  {
    url: '/satisfaction-survey',
    icon: SatisfactionSurveyIcon,
    permissions: ['satisfaction_survey:view', 'satisfaction_survey:download'],
  },
  {
    url: '/offline-clinic-booking',
    icon: OfflineClinicBookingIcon,
    permissions: [
      'offline_clinic_booking:view',
      'offline_clinic_booking:edit',
      'offline_clinic_booking:add',
    ],
  },
  {
    url: '/offline-clinic-schedule',
    icon: OfflineClinicScheduleIcon,
    permissions: [
      'offline_clinic_schedule:view',
      'offline_clinic_schedule:edit',
    ],
  },
  {
    url: '/apotek-dan-pengiriman',
    icon: ShippingIcon,
    permissions: ['pharmacy:view', 'pharmacy:edit'],
  },
  {
    url: '/daftar-transaksi',
    icon: TransactionIcon,
    permissions: [
      'transaction:view',
      'transaction:edit',
      'transaction:download',
    ],
  },
  {
    url: '/daftar-pelanggan',
    icon: UserIcon,
    permissions: ['customer:view', 'customer:add', 'customer:edit'],
  },
];
