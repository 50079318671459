<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body p-2">
                    <router-link to="/offline-clinic-booking/user" class="f-16 font-600 text-black">
                        <i class="fa fa-arrow-left"></i> User Information
                    </router-link>
                    <div class="row">
                        <div class="col-xl-6 col-md-12">
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Email:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.email }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Nama Lengkap:</p>
                                <p class="mb-0 f-14 text-black font-500">
                                    {{ user.firstName }} {{ user.lastName }}
                                </p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Nomor Handphone:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.mobileNumber }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Tanggal Lahir:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.dateOfBirth | moment("DD MMMM YYYY") }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Jenis Kelamin:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.gender }}</p>
                                <p
                                    class="mb-0 f-14 text-black font-500"
                                    v-for="(otherInfo, otherInfoKey) in user.maleGenderOtherInfo"
                                    :key="otherInfoKey"
                                >
                                    - {{ otherInfo }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12">
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Identity Card:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.idCard || '-' }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Omnicare EMR:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.omnicarePatientId || '-' }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Address:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ generateAddressString || '-' }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Address Detail:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.address && user.address.address || '-' }}</p>
                            </div>
                            <div class="mt-24">
                                <p class="mb-0 f-14 text-secondary">Address Note:</p>
                                <p class="mb-0 f-14 text-black font-500">{{ user.address && user.address.notes || '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end">
                        <router-link
                            :to="`/offline-clinic-booking/user/${user.id}/edit?source=user-detail`"
                            class="btn text-danger"
                        >
                            Edit
                        </router-link>
                        <router-link :to="`/offline-clinic-booking/user/${user.id}/appointment`" class="btn btn-primary">
                            Lanjut
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                user: {
                    id: this.$route.params.id,
                },
            }
        },
        mounted() {
            if (this.hasEditPermission) {
                this.getDetail();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
            generateAddressString() {
                if (
                    this.user.address
                    && this.user.address.province
                    && this.user.address.city
                    && this.user.address.suburb
                    && this.user.address.area
                    && this.user.address.postalCode
                ) {
                    const { province, city, suburb, area, postalCode } = this.user.address;
                    return `${province}, ${city}, ${suburb}, ${area} ${postalCode}`;
                }
                return '';
            }
        },
        methods: {
            getDetail() {
                this.axios.get(`/v2/clinical/offline-clinic-booking/users/${this.$route.params.id}`)
                    .then((res) => {
                        const userData = res.data.data;
                        const maleGenderOtherInfo = [];
                        if (userData.gender === 'F') {
                            if (userData.pregnant) {
                                maleGenderOtherInfo.push('Hamil');
                            }
                            if (userData.breastFeeding) {
                                maleGenderOtherInfo.push('Menyusui');
                            }
                            if (userData.promil) {
                                maleGenderOtherInfo.push('Program Hamil');
                            }
                            if (userData.kb) {
                                maleGenderOtherInfo.push('Menggunakan KB');
                            }
                            if (userData.preferFemaleDoctor) {
                                maleGenderOtherInfo.push('Menggunakan Hijab');
                            }
                        }

                        this.user = {
                            ...this.user,
                            ...userData,
                            gender: userData.gender === 'M' ? 'Laki-Laki' : 'Perempuan',
                            maleGenderOtherInfo,
                        }
                    })
            },
        },
    }
</script>
<style scoped>
    .mt-24 {
        margin-top: 24px;
    }
</style>