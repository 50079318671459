<template>
	<div class="section mw-100 p-2">
		<div class="card">
			<div class="card-body">
				<div class="d-flex gap-1 align-items-center flex-wrap">
					<div class="form-group" style="flex: 1 0 200px">
						<label class="f-12 mb-0.25">Clinic Name</label>
						<multiselect
							v-model="clinic"
							placeholder="Select Clinic"
							:options="clinicNameOptions"
							label="name"
							track-by="id"
							select-label=""
							deselect-label="Reset"
							:multiple="false"
							@input="getList();"
						>
							<span slot="noResult">Oops! No data found.</span>
						</multiselect>
					</div>
					<div class="form-group" style="flex: 1 0 200px">
						<label class="f-12 mb-0.25">Clinic Role</label>
						<multiselect
							v-model="clinicRole"
							placeholder="Select Role"
							:options="clinicRoleOptions"
							label="name"
							track-by="slug"
							select-label=""
							deselect-label="Reset"
							:multiple="false"
							@input="getList();"
						>
							<span slot="noResult">Oops! No data found.</span>
						</multiselect>
					</div>
					<div class="form-group" style="flex: 1 0 200px">
						<label class="f-12 mb-0.25">Schedule</label>
						<multiselect
							v-model="schedule"
							placeholder="Select Day"
							:options="scheduleOptions"
							label="name"
							track-by="id"
							select-label=""
							deselect-label="Reset"
							:multiple="false"
							@input="getList();"
						>
							<span slot="noResult">Oops! No data found.</span>
						</multiselect>
					</div>
					<div class="form-group" style="flex: 1 0 200px">
						<label class="f-12 mb-0.25">User Name</label>
						<div class="input-group">
							<span class="input-group-prepend">
								<div class="input-group-text border-right-0 bg-transparent text-blue">
									<i class="fa fa-search"></i>
								</div>
							</span>
							<input
								class="form-control border-left-0"
								v-model="search"
								@input="handleSearch"
								type="search"
								placeholder="Search by user name..."
							>
						</div>
					</div>
					<span class="font-500 pointer text-primary" @click="resetFilter">Reset Filter</span>
				</div>

				<!-- Table -->
				<div class="table-responsive">
					<table class="table mb-0">
						<thead>
							<tr>
								<th scope="col" class="font-600 text-black text-left">User Name</th>
								<th scope="col" class="font-600 text-black text-left">Clinic Roles</th>
								<th scope="col" class="font-600 text-black text-left">Clinic Name</th>
								<th scope="col" class="font-600 text-black text-left">Schedule</th>
								<th scope="col" class="font-600 text-black text-left">Day Off</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(result, key) in results.rows" :key="key">
								<td class="text-left align-top">
									<router-link
										v-if="hasEditPermission"
										:to="`/offline-clinic-schedule/${result.id}`"
										class="text-light-blue font-500 f-14"
									>
										{{ result.userName }} <i class="fa fa-ban text-danger" v-if="result.isTodayOff"></i>
									</router-link>
									<div v-else>
										{{ result.userName }} <i class="fa fa-ban text-danger" v-if="result.isTodayOff"></i>
									</div>
								</td>
								<td class="text-left align-top">{{ result.clinicRoleNames.join(', ') }}</td>
								<td class="text-left align-top">{{ result.clinicName }}</td>
								<td class="text-left align-top">
									<div v-for="(schedule, scheduleKey) in result.schedule" :key="scheduleKey">
										{{ uppercaseDay(schedule.day) }}, {{ schedule.startTime  }} - {{ schedule.endTime }}
									</div>
								</td>
								<td class="text-left align-top">
									<div v-for="(dayOff, dayOffKey) in result.dayOff" :key="dayOffKey">
										{{ dayOff.startDate | moment("DD MMM YY HH:mm") }} - {{ dayOff.endDate | moment("DD MMM YY HH:mm") }}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col text-center mt-1" v-if="!results.rows.length">
					<p class="f-14">There is no data</p>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-body d-flex flex-row">
				<PaginateList :data="results" />
			</div>
		</div>
	</div>
</template>
  
<script>
	import { debounce } from "debounce";
	import PaginateList from '@/components/PaginateList';
  
	export default {
    	components: {
      		PaginateList,
    	},
		data() {
			return {
				results: {
				rows: [],
				currentPage: 1,
				totalPages: 1,
				},
				limit: 10,
				// filters
				clinic: null,
				clinicNameOptions: [],
				clinicRole: null,
				clinicRoleOptions: [],
				schedule: null,
				scheduleOptions: [
					{ id: 'monday', name: 'Monday' },
					{ id: 'tuesday', name: 'Tuesday' },
					{ id: 'wednesday', name: 'Wednesday' },
					{ id: 'thursday', name: 'Thursday' },
					{ id: 'friday', name: 'Friday' },
					{ id: 'saturday', name: 'Saturday' },
					{ id: 'sunday', name: 'Sunday' },
				],
				search: '',
			};
		},
		mounted() {
			this.getClinicNameOptions();
			this.getClinicRoleOptions();
			this.getList();
			this.$root.$on('paginate', (data) => {
				this.getList(data);
			});
		},
		computed: {
			permissions() {
				return this.$store.getters.permissions;
			},
			hasEditPermission() {
				return this.permissions.includes('offline_clinic_schedule:edit');
			},
		},
		methods: {
			uppercaseDay(day) {
				return `${day.charAt(0).toUpperCase()}${day.slice(1)}`;
			},
			handleSearch: debounce(function() {
				this.getList();
			}, 1000),
			getClinicNameOptions() {
				this.axios
					.get('/v2/misc/offline-clinics')
					.then((res) => {
						this.clinicNameOptions = res.data.data.rows;
					})
					.catch((err) => {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
						});
				});
			},
			getClinicRoleOptions() {
				this.axios
					.get('/v2/misc/offline-clinic-roles')
					.then((res) => {
						this.clinicRoleOptions = res.data.data.rows;
					})
					.catch((err) => {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
						});
				});
			},
			getList(page = 1) {
				this.axios
					.get('/v2/clinical/offline-clinic-schedule', {
						params: {
							page,
							limit: this.limit,
							clinicId: this.clinic?.id ?? undefined,
							clinicRoleSlug: this.clinicRole?.slug ?? undefined,
							day: this.schedule?.id ?? undefined,
							...(this.search.replace(/\s/g,'').length > 0 && { search: this.search }),
						},
					})
					.then((res) => {
						this.results = res.data.data;
					})
					.catch((err) => {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
						});
					});
			},
			resetFilter() {
				this.clinic = null;
				this.clinicRole = null,
				this.schedule = null;
				this.search = '';
				this.getList();
			},
		},
	};
</script>
  
<style scoped>
	.mb-0\.25 {
		margin-bottom: 0.25rem;
	}
	.gap-1 {
		gap: 1rem;
	}
	.flex-1 {
		flex: 1;
	}
</style>
  